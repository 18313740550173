<script setup>
const configurationStore = useConfigurationStore()
const { generateBreadcrumbs } = useMetaUtils()
</script>

<template>
  <BaseContent
    v-if="configurationStore.configuration?.notFound"
    :content="configurationStore.configuration?.notFound?.content"
    :breadcrumbs="
      generateBreadcrumbs(configurationStore.configuration?.notFound)
    "
  />
  <PageNotFound v-else />
</template>
